import React, { useState } from 'react';
import NavigationBar from './Navbar/Navbar';
import Content from './Content/Content';
import Footer from './Footer/Footer';

function App() {
  const [active, setActive] = useState('home');

  return (
    <div className="base"
      style={{
        margin: '20px'
      }}
      >
      <NavigationBar active={active} setActive={setActive} />
      <div className="container">
        <Content active={active} setActive={setActive} />
      </div>
      <Footer setActive={setActive}/>
    </div>
  );
}

export default App;
