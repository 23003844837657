const Footer = ({ setActive }) => {
  const handleClick = (key) => {
    setActive(key);
    if (key !== 'contact') {
      document.getElementById('contact').classList.remove('active');
    } else {
      document.getElementById('contact').classList.add('active');
    }
  };

    return (
              
      <div class="container my-5">
        
        <footer class="text-center text-white" style={{backgroundColor: '#004AAC'}}>
          
          <div class="container">
            
            <section class="mt-5">
              
              <div class="row text-center d-flex justify-content-center pt-5">
                
                <div className="col-md-2">
                  <h6 className="text-uppercase font-weight-bold">
                    <a href="#home" className="text-white" onClick={() => handleClick('home')}>Home</a>
                  </h6>
                </div>

                <div className="col-md-2">
                  <h6 className="text-uppercase font-weight-bold">
                    <a href="#about" className="text-white" onClick={() => handleClick('about')}>About Us</a>
                  </h6>
                </div>

                <div className="col-md-2">
                  <h6 className="text-uppercase font-weight-bold">
                    <a href="#solutions" className="text-white" onClick={() => handleClick('solutions')}>Solutions</a>
                  </h6>
                </div>

                <div className="col-md-2">
                  <h6 className="text-uppercase font-weight-bold">
                    <a href="#contact" className="text-white" onClick={() => handleClick('contact')}>Contacts</a>
                  </h6>
                </div>
                
                
              </div>
              
            </section>
          </div>
          <div
              class="text-center p-3"
              style={{backgroundColor: '#004AAC', color: 'white'}}
          >
            © 2021 Copyright: {' '}Lightstate Inc.
          </div>
          
        </footer>
        
      </div>

    );
    }

export default Footer;