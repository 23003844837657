import React from 'react';
import './Content.css';

const Content = ({ active, setActive }) => {
  let content;

  switch (active) {
    case 'home':
      content = <Home setActive={setActive} />;
      break;
    case 'about':
      content = <About setActive={setActive} />;
      break;
    case 'solutions':
      content = <Solutions  setActive={setActive} />;
      break;
    case 'contact':
      content = <Contact />;
      break;
    default:
      content = <Home />;
  }

  return <div className="content">{content}</div>;

};

const Home = ({setActive}) => {
  const handleLearnMoreClick = () => {
    setActive('about');
  };
  return (
    <div className="content-home">
      
      <img src="/hot-glass-top.jpg" alt="hot-glass" style={{ width: '100%' }} />

      <div class="jumbotron">
      <h1>We offer expert solutions and services in lubricants and coatings for the glass manufacturing industry!</h1>
      <br></br>
      <p><a class="btn btn-primary btn-lg" href="#about" role="button" onClick={handleLearnMoreClick} id="home-learn-more">Learn more</a></p>
    </div>
    </div>
  );
}

const About = ({setActive}) => {
  const handleFindSolutionClick = () => {
    setActive('solutions');
  };
  return (
    <div className="content-about">
      <img src="/rotary-blowing.jpg" alt="rotary-blowing" style={{ width: '100%' }} />
      <div class="card">
        <div class="card-body">
        <h4 style={{fontFamily: "Gill Sans", fontSize: "1.5rem"}}> Building on our deep understanding of the industry's needs, we pride ourselves on delivering exceptional customer service and tailored solutions. Our commitment goes beyond just supplying products; we strive to forge long-term partnerships, supporting our clients at every step to ensure their success and satisfaction. This customer-centric approach is at the heart of our business, as we continuously seek to innovate and improve, keeping your glass manufacturing processes smooth and efficient.</h4>
        </div>
        <div class="d-flex justify-content-center" >
          <button type="button" class="btn btn-primary btn-lg btn-block" style={{ width: '30%' }} onClick={handleFindSolutionClick}>Find our solution</button>
        </div>

      </div>
    </div>
  );
}

const Solutions = ({setActive}) => {
  const handleContactUsButton = (selectedKey) => {
    setActive('contact');
    
    document.getElementById('contact').classList.add('active');
    
  };
  return (
    <div className="content-solution">
      <div class="card">
        <img class="card-img-top" src="/perform-bottom.jpg" alt="glass-bottom"></img>
        <div class="card-header">
          <h4>Glass container manufacturing solutions</h4>
        </div>
        <div class="card-header">
          <h5>Hot End</h5>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-info">Shear blade oils</li>
          <li class="list-group-item ">Scoop and delivery oils</li>
          <li class="list-group-item list-group-item-info">Mold pre-coating / Release Agent</li>
          <li class="list-group-item ">Mold Swabbing Agent</li>
          <li class="list-group-item list-group-item-info">Mold Spraying Agent</li>
          <li class="list-group-item ">Hot convey belt / hot end contacting coating</li>
        </ul>
        <div class="card-header">
          <h5>Cold End (Food Grade)</h5>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-info">Container Scratch Resistance Coating</li>
          <li class="list-group-item ">Convey belt coating</li>
          
        </ul>
      </div>
      <div class="d-flex justify-content-center m-4" >
        <button type="button" class="btn btn-primary btn-lg btn-block" style={{ width: '30%' }} onClick={handleContactUsButton}>Contact Us</button>
      </div>
      <div class="card">
        <img class="card-img-top" src="/metal-processing.jpg" alt="glass-bottom"></img>
        <div class="card-header">
          <h4>Metal processing solutions</h4>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item list-group-item-info">Cutting Fluids</li>
          <li class="list-group-item ">Stamping and Forming Oils</li>
          <li class="list-group-item list-group-item-info">Anti-rust Coating</li>
          <li class="list-group-item ">Degreaser</li>
        </ul>
      </div>
      <div class="d-flex justify-content-center m-4" >
        <button type="button" class="btn btn-primary btn-lg btn-block" style={{ width: '30%' }} onClick={handleContactUsButton}>Contact Us</button>
      </div>
    </div>
  );
}

const Contact = () => {
  return (
    <div className="content-contact">
      <img src="/glass-pieces.jpg" alt="glass-pieces" style={{ width: '100%' }} />
      <div class="d-flex justify-content-center">
          <img src="/handshake.jpg" alt="handshake" style={{ width: '30%' }} />
          {/* add email info@vueton.com */}
          <div class="d-flex align-items-center "> 
            <h5 style={{fontFamily: "Gill Sans", fontSize: "1.5rem"}} className='ml-3 mr-1'>
              Email us at: {' '}
              <a href="mailto:info@vueton.com"> {'info@vueton.com'}</a>
            </h5>              
          </div>
      </div>
    </div>
  );
}

export default Content;
