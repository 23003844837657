import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Navbar.css'; // Ensure this CSS file is imported

const NavigationBar = ({ active, setActive }) => {
  // Handler for when a Nav.Link is clicked
  const handleSelect = (selectedKey) => {
    console.log(`selected ${selectedKey}`);
    setActive(selectedKey);
    // check if the selectedKey isn't contact, remove one or more 'active' class from contact element, getting it from 
    if (selectedKey !== 'contact') {
      document.getElementById('contact').classList.remove('active');
    } else {
      document.getElementById('contact').classList.add('active');
    }
  };

  return (
    <Navbar bg="light" expand="lg" collapseOnSelect className="navbar-custom" onSelect={handleSelect}>
      <Navbar.Brand href="#home" className="navbar-brand-custom">
        <img
          alt=""
          src="/vueton-logo.png"
          height="50"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mx-auto" activeKey={active}>
          <Nav.Link eventKey="home" href="#home">Home</Nav.Link>
          <Nav.Link eventKey="about" href="#about">About Us</Nav.Link>
          <Nav.Link eventKey="solutions" href="#solutions">Solutions</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link eventKey="contact" href="#contact" id='contact'>Contacts</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
